// react-select theme
export default {
  placeholder: (base) => ({
    ...base,
    width: '80%',
    fontWeight: 600,
    color: '#9cb3b8',
    textAlign: 'left',
    marginLeft: 0,
    marginRight: 0
  }),
  control: (base, state) => ({
    ...base,
    background: 'white',
    border: state.isFocused ? '1px solid #0A899A' : 'none',
    borderRadius: 6,
    height: 'auto',
    minHeight: 40,
    maxHeight: 150,
    overflow: 'auto',
    boxShadow: state.isFocused ? '0 2px 6px 1px rgba(33, 116, 128, .27)' : 'none',
    '&:hover': {
      boxShadow: '0 2px 6px 1px rgba(33, 116, 128, .27)'
    },
    borderBottomLeftRadius: state.selectProps.menuIsOpen ? 0 : 6,
    borderBottomRightRadius: state.selectProps.menuIsOpen ? 0 : 6,
    padding: state.selectProps.isMulti ? '0 8px' : '0 0 0 8px',
    cursor: 'pointer'
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    background: '#dae4e6',
    display: state.selectProps.isMulti ? 'none' : 'inherit'
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    display: state.selectProps.isMulti && !state.selectProps.isLoading ? 'none' : 'inherit'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: '#9cb3b8',
    '&:hover': {
      color: '#9cb3b8'
    },
    display: state.selectProps.isMulti ? 'none' : 'inherit'
  }),
  singleValue: (base, state) => ({
    ...base,
    fontWeight: 600,
    color: state.isDisabled ? '#acc7cb' : '#0A899A',
    textAlign: 'center'
  }),
  option: (base, state) => ({
    ...base,
    fontWeight: 600,
    padding: 12,
    color: state.isSelected ? '#0A899A' : '#043b42',
    background: state.isFocused ? '#f8f8f8' : 'none',
    '&:not(:last-child)': {
      borderBottom: '1px solid #dae4e6'
    },
    '&:active': {
      background: '#f3f3f3'
    }
  }),
  menuList: (base) => ({
    ...base,
    marginTop: '-1px'
  }),
  menu: (base) => ({
    ...base,
    marginTop: '-1px',
    borderRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    border: '1px solid #0A899A',
    borderTop: 'none',
    boxShadow: '0 2px 6px 1px rgba(33, 116, 128, .27)'
  }),
  groupHeading: (base) => ({
    ...base,
    background: '#fafafa',
    color: '#60818A',
    fontWeight: '700',
    letterSpacing: '1px',
    fontSize: 10,
    padding: 8,
    paddingHorizontal: 12,
    borderTop: '1px solid #dae4e6',
    borderBottom: '1px solid #dae4e6',
    marginTop: -13
  }),

  multiValue: (base) => ({
    ...base,
    background: '#e9f3f5',
    color: '#0A899A',
    fontWeight: 700,
    borderRadius: 6,
    margin: '1px 4px'
  }),

  multiValueLabel: (base) => ({
    ...base,
    background: '#e9f3f5',
    borderRadius: 6,
    color: '#0A899A',
    fontWeight: 700
  })
}
