// @flow

import React from 'react'

import { CCDropdown } from '../../UI'
import type { DropdownOption } from '../../UI/CCDropdown/CCDropdown'

import './EditSubscriberAttributes.scss'

type Props = {
  attributeId: string,
  attributeOptions: Object,
  handleChange: Function,
  handleDelete: Function
}

export function RemoveAttributeValue (props: Props = {}) {
  return (
    <div className="edit-attribute-item__row">
      <div className="edit-attribute-item__row__dropdown">
        <CCDropdown
          autoCompleteEnabled
          truncateAfter={10}
          options={props.attributeOptions}
          value={props.attributeId}
          onChange={(option: DropdownOption) => props.handleChange(option.value, props.attributeId)}
          flexible
        />
      </div>
      <div className='edit-attribute-item__row__remove-button'>
        <i
          data-testid="delete-row"
          onClick={() => props.handleDelete(props.attributeId)}
          className="fa fa-trash"
        />
      </div>
    </div>
  )
}
