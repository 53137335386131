// @flow

import React from 'react'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'

import './CCButton.scss'

type Props = {
  customClasses?: string,
  onClick?: Function,
  color?: string,
  disabled?: boolean,
  submit?: boolean,
  children?: any,
  tooltip?: string,
  icon?: string,
  onlyIcon?: boolean,
  small?: boolean
}

export function CCButton (props: Props) {
  const buttonClasses = classnames('cc-button', props.color, props.customClasses, {
    'cc-button--disabled': props.disabled,
    'cc-button--small': props.small
  })

  function renderIcon () {
    if (!props.icon) return

    const iconClasses = classnames('fa', {
      [`fa-${props.icon}`]: true,
      'margin-right-10': !props.onlyIcon
    })

    return (<i className={iconClasses}/>)
  }

  function renderDisabled () {
    return (
      <div className={buttonClasses}>
        {renderIcon()}
        {props.children}
      </div>
    )
  }

  if (props.disabled) return renderDisabled()

  return (
    <div
      data-tip={props.tooltip}
      className={buttonClasses}
      onClick={props.onClick}
    >
      {renderIcon()}
      {props.children}
      {props.submit &&
      <input type="submit" style={{ visibility: 'hidden', padding: 0, width: 0, height: 0 }}/>}
      {(props.tooltip) && <ReactTooltip delayShow={1000}/>}
    </div>
  )
}
