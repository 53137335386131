// @flow

import React, { useState } from 'react'
import _ from 'lodash'

import type { GenericEventConfig, TrackingEvent } from '@chatchamp/chatchamp-types'

import { type DropdownOption, CCDropdown, CCPanel, CCTextInput } from '../../../../UI'

import { Shop } from '../../../../../models'

import { GenericEventConfigPanel } from './GenericEventConfigPanel'

import './GenericEventsPanel.scss'

export type DefaultPayloads = {
  [key: TrackingEvent]: Object
}

type Props = {
  shop: Shop,
  onChange: Function,
  eventsProp: string,
  eventOptions: Array<DropdownOption>,
  panelTitle: string,
  trackerPropertyPath: string,
  eventConfigPanel?: React$StatelessFunctionalComponent<any>,
  isEventNameCustomizable?: boolean,
  defaultPayloads?: DefaultPayloads
}

export function GenericEventsPanel ({
  shop,
  onChange,
  eventsProp,
  eventOptions,
  panelTitle,
  trackerPropertyPath,
  eventConfigPanel,
  isEventNameCustomizable,
  defaultPayloads = {}
}: Props) {
  const [genericEventConfigPanelKey, setGenericEventConfigPanelKey] = useState('genericEventConfigPanelKey')

  function onChangeEvent (index: number, property: string, value: any): void {
    const config = _.get(shop, trackerPropertyPath)
    if (property === 'eventName' && !config[eventsProp][index].payload && defaultPayloads[value]) {
      config[eventsProp][index].payload = defaultPayloads[value]
      setGenericEventConfigPanelKey('updatedGenericEventConfigPanel')
      setTimeout(() => setGenericEventConfigPanelKey('genericEventConfigPanelKey'), 200)
    }
    config[eventsProp][index][property] = value
    onChange(trackerPropertyPath, config)
  }

  function onDeleteEvent (index: number): void {
    const config = _.get(shop, trackerPropertyPath)
    config[eventsProp].splice(index, 1)
    onChange(trackerPropertyPath, config)
  }

  function onAddEvent (): void {
    const config = _.get(shop, trackerPropertyPath)
    config[eventsProp].push({})
    onChange(trackerPropertyPath, config)
  }

  const events = _.get(_.get(shop, trackerPropertyPath, {}), eventsProp, [])

  const renderEventRow = (event: GenericEventConfig, index: number) => {
    return (<div key={index} className="generic-events-panel__row">
      <div className="generic-events-panel__row__column">
        <div className="settings-input">
          <h3 className="settings-subheader">
            Event
          </h3>
          <CCDropdown
            placeholder="Event"
            options={eventOptions}
            onChange={(option) => onChangeEvent(index, 'eventName', option.value)}
            value={event.eventName}/>
        </div>
        {isEventNameCustomizable && (<div>
          <h3 className="settings-subheader">
            Event Name
          </h3>
          <CCTextInput
            placeholder={`Event Name${event.eventName ? ` (default value: ${event.eventName})` : ''}`}
            onChange={(val) => onChangeEvent(index, 'customEventName', val)}
            value={event.customEventName}/>
        </div>)}
      </div>{(eventConfigPanel
        ? React.createElement(eventConfigPanel, { index, event, onChangeEvent })
        : <GenericEventConfigPanel key={genericEventConfigPanelKey} index={index} event={event} onChangeEvent={onChangeEvent} jsonEditorHeight={isEventNameCustomizable ? '125px' : '100px'} />)}
      <div className="generic-events-panel__row__column">
        <div className="page-delete-button">
          <i
            onClick={() => onDeleteEvent(index)}
            className="fa fa-trash"
          />
        </div>
      </div>
    </div>)
  }

  return (
    <CCPanel
      title={panelTitle}
      bottomButtonTitle="ADD NEW EVENT"
      bottomButtonIcon="plus"
      onBottomButtonClick={onAddEvent}
    >
      <div className="generic-events-panel">
        {events.map((event, index: number) => renderEventRow(event, index))}
      </div>
    </CCPanel>
  )
}
