// @flow

import React, { useEffect, type Node } from 'react'
import { useSortBy, useTable } from 'react-table'
import type { Row as ReactTableRow } from 'react-table'

// $FlowFixMe
import fontAwesome from 'font-awesome/css/font-awesome.css'

import { CCSpinnerPage } from '..'

import { Row } from './components/Row/Row'
import type { CCTableColumn } from './types/CCTableColumn'

import './CCTable.scss'
import styles from './CCCustomizableTable.css'

const { fa, 'fa-caret-down': faCaretDown, 'fa-caret-up': faCaretUp } = fontAwesome

type Props = {
  data: Array<any>,
  columns: Array<CCTableColumn>,
  dropdownMenu?: Function,
  customRowClasses?: string,
  onRowDelete?: Function,
  rowDeletable?: boolean,
  onRowClick?: Function,
  controls?: Node,
  isLoading?: boolean,
  tableStyle?: 'default' | 'skinny',
  summaryRow?: Object,
  customSort?: Function,
  onSortingChange?: Function
}

export function CCTable ({
  data,
  dropdownMenu,
  customRowClasses = '',
  onRowDelete,
  rowDeletable = true,
  onRowClick,
  columns,
  controls,
  isLoading = false,
  tableStyle = 'default',
  summaryRow,
  customSort,
  onSortingChange
}: Props) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: { sortBy } } = useTable({ columns, data, manualSortBy: Boolean(onSortingChange) }, useSortBy)

  useEffect(() => {
    // react-table has a strange api where the order is always `desc` but with values true and false
    const normalizedSortBy = sortBy.map(({ id, desc }) => {
      return {
        id,
        order: desc ? 'DESC' : 'ASC'
      }
    })

    onSortingChange && onSortingChange(normalizedSortBy)
  }, [onSortingChange, sortBy])

  if (customSort) rows.sort(customSort)

  const createDropdownMenu = (row: ReactTableRow) => {
    if (!dropdownMenu) return
    const menu = dropdownMenu(row)
    menu.push({
      id: 'delete',
      title: 'Delete',
      htmlId: 'delete-button',
      onClick: rowDeletable ? onRowDelete : null
    })
    return menu
  }

  return (
    <div>
      <style dangerouslySetInnerHTML={{
        __html: [
          '.react-select__single-value:before {',
          '  content: "Columns: ";',
          '  color: #a0a0a0;',
          '}'
        ].join('\n')
      }}/>
      {controls && (<div className={styles.control_container}>
        {controls}
      </div>)}
      <div className={styles.table_container}>
        {isLoading
          ? (<CCSpinnerPage/>)
          : (
            <table {...getTableProps()} className={`cc-table ${tableStyle === 'skinny' ? 'skinny' : ''}`}>
              <thead>
                {headerGroups.map(headerGroup => {
                  const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
                  return (
                    <tr key={key} {...restHeaderGroupProps}>
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps(column.getSortByToggleProps())
                        return (
                          <th key={key} {...restColumn}>
                            {column.render('Header')}
                            <span>
                              {column.isSorted
                                ? (column.isSortedDesc
                                    ? <i className={[fa, faCaretUp, styles.sorting_caret].join(' ')}/>
                                    : <i className={[fa, faCaretDown, styles.sorting_caret].join(' ')}/>)
                                : ''}
                            </span>
                          </th>
                        )
                      })}
                      {!!dropdownMenu && (<th/>)}
                    </tr>
                  )
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row)
                  const isSummaryRow = summaryRow && (row.original.id === 'summary')
                  return (<Row
                    customRowClasses={isSummaryRow ? `summary-row ${customRowClasses}` : customRowClasses}
                    columns={columns}
                    tableHasDropdownMenu={!!dropdownMenu}
                    dropdownMenu={isSummaryRow ? [] : createDropdownMenu(row)}
                    key={row.original.id ?? row.id}
                    row={row}
                    onRowClick={isSummaryRow ? null : onRowClick}
                  />)
                })}
              </tbody>
            </table>)}
      </div>
    </div>
  )
}
