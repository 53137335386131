// @flow

import qs from 'qs'

import { Shop } from '../models'

function _formatUrl (url: string, params: Object): string {
  const format: string = params.format ? `.${params.format}` : params.format === '' ? '' : '.json'
  const anchor: string = params.anchor ? `#${params.anchor}` : ''
  const queryParams: Object = params.queryParams ? `?${qs.stringify(params.queryParams)}` : ''

  return url + format + queryParams + anchor
}

export class CCRoutes {
  static shopSettingsPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/settings`, params)
  }

  static usersPath (accountId: number, params: Object = {}): string {
    return _formatUrl(`/accounts/${accountId}/users`, params)
  }

  static logoutPath (params: Object = {}): string {
    return _formatUrl('/logout', params)
  }

  static subscribersPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/subscribers`, params)
  }

  static subscriberPath (id: number, params: Object = {}): string {
    return _formatUrl(`/subscribers/${id}`, params)
  }

  static accountsPath (params: Object = {}): string {
    return _formatUrl('/accounts', params)
  }

  static accountPath (id: number, params: Object = {}): string {
    return _formatUrl(`/accounts/${id}`, params)
  }

  static subscriberAttributesPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/subscriber_attributes`, params)
  }

  static subscriberAttributePath (id: number, params: Object = {}): string {
    return _formatUrl(`/subscriber_attributes/${id}`, params)
  }

  static updateSubscriberAttributePath (shop: Shop, id: number, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/subscriber_attributes/${id}`, params)
  }

  static updateInBatchAttributeValuesPath (params: Object = {}): string {
    return _formatUrl('/attribute_values/update_in_batch', params)
  }

  static attributeValuesPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/attribute_values`, params)
  }

  static searchAttributeValuesPath (params: Object = {}): string {
    return _formatUrl('/attribute_values/search', params)
  }

  static blockPath (id: number, params: Object = {}): string {
    return _formatUrl(`/blocks/${id}`, params)
  }

  static blocksPath (params: Object = {}): string {
    return _formatUrl('/blocks', params)
  }

  static buttonPath (id: number, params: Object = {}): string {
    return _formatUrl(`/buttons/${id}`, params)
  }

  static buttonsPath (params: Object = {}): string {
    return _formatUrl('/buttons', params)
  }

  static newButtonPath (params: Object = {}): string {
    return _formatUrl('/buttons/new', params)
  }

  static editButtonPath (id: number, params: Object = {}): string {
    return _formatUrl(`/buttons/${id}/edit`, params)
  }

  static sortButtonsPath (params: Object = {}): string {
    return _formatUrl('/buttons/sort', params)
  }

  static channelPath (id: number, params: Object = {}): string {
    return _formatUrl(`/shops/${id}`, params)
  }

  static messagePath (id: number, params: Object = {}): string {
    return _formatUrl(`/messages/${id}`, params)
  }

  static messagesPath (params: Object = {}): string {
    return _formatUrl('/messages', params)
  }

  static sortMessagesPath (params: Object = {}): string {
    return _formatUrl('/messages/sort', params)
  }

  static messageItemPath (id: number, params: Object = {}): string {
    return _formatUrl(`/message_items/${id}`, params)
  }

  static messageItemsPath (params: Object = {}): string {
    return _formatUrl('/message_items', params)
  }

  static sortMessageItemsPath (params: Object = {}): string {
    return _formatUrl('/message_items/sort', params)
  }

  static valuesProductAttributesPath (params: Object = {}): string {
    return _formatUrl('/product_attributes/values', params)
  }

  static valueExistsProductAttributesPath (params: Object = {}): string {
    return _formatUrl('/product_attributes/value_exists', params)
  }

  static productPath (id: number, params: Object = {}): string {
    return _formatUrl(`/products/${id}`, params)
  }

  static productsPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/products`, params)
  }

  static deleteAllProductsPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/products/delete_all`, params)
  }

  static categoriesProductsPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/products/categories`, params)
  }

  static importFromFileProductsPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/products/import_from_file`, params)
  }

  static importFromSettingsProductsPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/products/import_from_settings`, params)
  }

  static segmentPath (shop: Shop, id: number, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/segments/${id}`, params)
  }

  static segmentsPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/segments`, params)
  }

  static statisticsSegmentsPath (params: Object = {}): string {
    return _formatUrl('/segments/statistics', params)
  }

  static jobsPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/jobs`, params)
  }

  static currentUserPath (params: Object = {}): string {
    return _formatUrl('/users/current_user', params)
  }

  static updateMFAPath (params: Object = {}): string {
    return _formatUrl('/users/update_mfa', params)
  }

  static editPasswordPath (params: Object = {}): string {
    return _formatUrl('/users/edit_password', params)
  }

  static updatePasswordPath (params: Object = {}): string {
    return _formatUrl('/users/update_password', params)
  }

  static issuesPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/issues`, params)
  }

  static scanIssuesPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/issues/scan`, params)
  }

  static ignoreIssuePath (shop: Shop, id: number, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/issues/${id}/ignore`, params)
  }

  static unignoreIssuePath (shop: Shop, id: number, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/issues/${id}/unignore`, params)
  }

  static importKnowledgeItemsPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/knowledge_items/import`, params)
  }

  static knowledgeItemsPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/knowledge_items`, params)
  }

  static knowledgeItemsUploadStatusPath (shop: Shop, params: Object = {}): string {
    return _formatUrl(`/shops/${shop.secure_id}/knowledge_items/upload_status`, params)
  }

  static inviteUserPath (accountId: number, params: Object = {}): string {
    return _formatUrl(`/accounts/${accountId}/users/invite`, params)
  }

  static resentInviteUserPath (accountUserId: number, params: Object = {}): string {
    return _formatUrl(`/users/${accountUserId}/resend_invite`, params)
  }

  static promoteUserPath (accountUserId: number, params: Object = {}): string {
    return _formatUrl(`/users/${accountUserId}/promote`, params)
  }

  static demoteUserPath (accountUserId: number, params: Object = {}): string {
    return _formatUrl(`/users/${accountUserId}/demote`, params)
  }

  static deleteUserPath (accountUserId: number, params: Object = {}): string {
    return _formatUrl(`/users/${accountUserId}`, params)
  }
}
