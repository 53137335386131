// @flow

import _ from 'lodash'
import React, { useState, useEffect } from 'react'

import { CCButton, CCModal, CCTextInput, showErrorMessages, showSuccessMessage } from '../UI'

import { Wizard } from '../../models'
import { WizardsService } from '../../services'

type Props = {
  wizard: Wizard,
  onDuplicate?: Function,
  isOpen: boolean,
  onRequestClose: Function
}

export function DuplicateWizardModal ({ wizard, onDuplicate, isOpen, onRequestClose }: Props) {
  const [inputName, setInputName] = useState(`Copy ${wizard.name}`)

  useEffect(() => {
    setInputName(`Copy ${wizard.name}`)
  }, [wizard])

  async function handleFormSubmit () {
    try {
      const result = await WizardsService.duplicate(wizard, { name: inputName })
      onRequestClose()
      showSuccessMessage('Wizard is being duplicated.')
      if (onDuplicate) onDuplicate(result.job_id)
    } catch (e) {
      showErrorMessages(e)
    }
  }

  return <CCModal title="Duplicate Wizard" width={700} isOpen={isOpen}>
    <div className="cc-modal__field">
      <div className="cc-modal__horizontal-input">
        <div className="cc-modal__field__label">Name:</div>
        <div className="cc-modal__field__body">
          <CCTextInput value={inputName} onChange={setInputName} error={_.isEmpty(inputName)}/>
        </div>
      </div>
    </div>
    <div className="cc-modal__footer">
      <CCButton onClick={onRequestClose}>
        Cancel
      </CCButton>

      <CCButton
        color="primary" onClick={handleFormSubmit}
        disabled={_.some([inputName], _.isEmpty)}>Save</CCButton>
    </div>
  </CCModal>
}
