// @flow

import React from 'react'

import { CCButtonDropdown, CCButtonDropdownItem } from '../../..'
import type { CCTableColumn } from '../../types/CCTableColumn'
import type { DropdownItem } from './DropdownItem'
import styles from './Row.css'

type Props = {
  columns: Array<CCTableColumn>,
  onRowClick?: Function,
  row: Object,
  dropdownMenu: Object,
  customRowClasses: string,
  tableHasDropdownMenu: boolean
}

export function Row (props: Props) {
  const renderDropdownItem = (dropdownItem: DropdownItem) => {
    return (
      <CCButtonDropdownItem onClick={() => dropdownItem.onClick(props.row.original)} id={dropdownItem.htmlId}>
        {dropdownItem.title}
      </CCButtonDropdownItem>
    )
  }

  const anyAction = () => {
    return props.dropdownMenu.some((dropdownItem) => !!dropdownItem.onClick)
  }

  return (
    <tr {...props.row.getRowProps()}
        className={props.onRowClick ? styles.clickable_row : ''}
        onClick={() => props.onRowClick && props.onRowClick(props.row.original)}
    >
      {props.row.cells.map(cell => {
        const { key, ...restCellProps } = cell.getCellProps([{ className: props.customRowClasses }])
        return (
          <td key={key} {...restCellProps}>
            {cell.render('Cell')}
          </td>
        )
      })}
      {props.tableHasDropdownMenu &&
        <td>
          <div>
            {anyAction() && <CCButtonDropdown>
              {props.dropdownMenu.map((dropdownItem) => (
                <span key={dropdownItem.id}>
                      {dropdownItem.onClick && renderDropdownItem(dropdownItem)}
                    </span>
              ))}
            </CCButtonDropdown>}
          </div>
        </td>}
    </tr>
  )
}
