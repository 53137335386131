// @flow

/* eslint-disable camelcase */

import { type MessageData, WizardPageOption } from '@chatchamp/chatchamp-types'

import { MessageService } from '../services'

export class Message {
  id: number
  block_id: number
  buttons: Array<Object>
  buttons_with_block: Array<Object>
  campaign_id: number
  data: MessageData
  editing_possible: boolean
  error_when_sent: Object
  incount: number
  to_s: string
  is_last_message_of_block: boolean
  message_type: string
  message_items: Array<Object>
  reachable: boolean
  routing: Object
  step: number
  text: string
  title: string
  validation_expression: ?string
  validation_message: ?string
  validation_type: string
  video_url: ?string
  image_url: ?string
  webhook_url: ?string
  webhook_data: ?string

  constructor (message: Object) {
    message && Object.assign(this, message)
    this.data = this.data || {}
    this.data.possibleValues = this.data.possibleValues || []
    this.data.attributeId = this.data.attributeId || -1
    this.data.number_of_products = this.data.number_of_products || 5
    this.data.live_chat = this.data.live_chat || {}
    this.data.product = this.data.product || {}
  }

  // eslint-disable-next-line no-use-before-define
  async update (values: Object): Promise<Message> {
    const updatedMessage = await MessageService.updateMessage(this.id, values)
    return Object.assign(this, updatedMessage)
  }

  isText (): boolean {
    return this.message_type === 'text'
  }

  isInput (): boolean {
    return this.message_type === 'input'
  }

  isGallery (): boolean {
    return this.message_type === 'gallery'
  }

  isProducts (): boolean {
    return this.message_type === 'products'
  }

  isRoutine (): boolean {
    return this.isProducts() && !!this.data.product.is_routine
  }

  isProgressBar (): boolean {
    return this.message_type === 'progress_bar'
  }

  isJavaScript (): boolean {
    return this.message_type === 'javascript'
  }

  isCode (): boolean {
    return this.message_type === 'code'
  }

  isImage (): boolean {
    return this.message_type === 'image'
  }

  isVideo (): boolean {
    return this.message_type === 'video'
  }

  isTiming (): boolean {
    return this.message_type === 'timing'
  }

  isList (): boolean {
    return this.message_type === 'list'
  }

  isRouting (): boolean {
    return this.message_type === 'routing'
  }

  isGoogleAnalytics (): boolean {
    return this.message_type === 'google_analytics'
  }

  isLiveChat (): boolean {
    return this.message_type === 'live_chat'
  }

  isSelect (): boolean {
    return this.message_type === 'select'
  }

  isAbRouting (): boolean {
    return this.message_type === 'ab_routing'
  }

  isWebhook (): boolean {
    return this.message_type === 'webhook'
  }

  isEditSubscriberAttributes (): boolean {
    return this.message_type === 'edit_subscriber_attributes'
  }

  isCustomConversion (): boolean {
    return this.message_type === 'custom_conversion'
  }

  isCustomerService (): boolean {
    return this.message_type === 'customer_service'
  }

  isOrderStatus (): boolean {
    return this.message_type === 'order_status'
  }

  isEmail (): boolean {
    return this.message_type === 'email'
  }

  canHaveButtons (): boolean {
    return this.isText()
  }

  routineItems (): Array<Object> {
    return this.data.product?.routine_items || []
  }

  wizardPageType (): string {
    if (this.isRoutine()) return 'routine'
    if (this.isSelect() && this.data.selectType) return this.data.selectType

    return this.message_type
  }

  isWizardLogicElement (): boolean {
    return !['text', 'gallery', 'input', 'select', 'products'].includes(this.message_type)
  }

  updatePossibleValues (option: WizardPageOption): Array<WizardPageOption> {
    if (this.data.possibleValues.find(o => o.uuid === option.uuid)) {
      return this.data.possibleValues.map(o => o.uuid === option.uuid ? option : o)
    } else {
      return [...this.data.possibleValues, option]
    }
  }

  isConsideringOrderOfCategories (): boolean {
    return this.data.consider_order_of_categories
  }
}

/* eslint-enable camelcase */
