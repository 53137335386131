// @flow

import React from 'react'

import { Shop } from '../../models'

import { useShop, useUser } from '../../hooks'

import { SearchModal } from '../SearchModal/SearchModal'

import { ShopPicker } from './ShopPicker/ShopPicker'

import { SideNavItem } from './SideNavItem'
import { SideNavHeader } from './SideNavHeader'
import logo from './logo.png'

import './SideNav.scss'

export type Props = {
  adminPath: string,
  shops: Array<Shop>
}

const ITEMS = {
  DASHBOARD: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/dashboard`,
    name: 'Dashboard',
    icon: 'fa-dashboard',
    id: 'sidenav-dashboard'
  },
  USER_INSIGHTS: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/insights`,
    name: 'User Insights',
    icon: 'fa-pie-chart',
    id: 'user-insights'
  },
  CHATBOTS: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/chatbots`,
    regex: /(\/shops\/\w+)?\/chatbots(?=(\/\d)|($))/,
    name: 'Chatbots',
    icon: 'fa-paper-plane',
    id: 'sidenav-chatbot'
  },
  WELCOME_MESSAGES: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/welcome_messages`,
    name: 'Welcome Messages',
    icon: 'fa-window-maximize',
    id: 'sidenav-welcome-message'
  },
  CHATBOT_SETTINGS: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/chatbots/settings`,
    name: 'Chatbot Settings',
    icon: 'fa-gear',
    id: 'sidenav-chatbot-settings'
  },
  WIZARDS: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/wizards`,
    name: 'Wizards',
    icon: 'fa-desktop',
    id: 'wizard'
  },
  SUBSCRIBERS: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/subscribers`,
    name: 'Users',
    icon: 'fa-users',
    id: 'sidenav-subscribers'
  },
  SUBSCRIBER_ATTRIBUTES: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/subscriber_attributes`,
    name: 'Attributes',
    icon: 'fa-tag',
    id: 'sidenav-attributes'
  },
  SEGMENTS: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/segments`,
    name: 'Segments',
    icon: 'fa-pie-chart',
    id: 'sidenav-segments'
  },
  CUSTOMER_SERVICE_CONVERSATIONS: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/customer_service_conversations`,
    name: 'AI Conversations [A]',
    icon: 'fa-android',
    id: 'customer-support-conversation'
  },
  PRODUCTS: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/products`,
    regex: /\/products(?=(\/\d)|($))/,
    name: 'Products',
    icon: 'fa-list',
    id: 'sidenav-products'
  },
  FEED_SETTINGS: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/products/feed_settings`,
    name: 'Feed Settings',
    icon: 'fa-rss',
    id: 'sidenav-feed-settings'
  },
  RECOMMENDER_SETTINGS: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/recommender_settings`,
    name: 'Rec. Settings',
    icon: 'fa-sliders',
    id: 'sidenav-recommender-settings'
  },
  INTEGRATION: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/settings/integration`,
    name: 'Integration',
    icon: 'fa-cogs',
    id: 'sidenav-integration'
  },
  TRACKING: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/settings/tracking`,
    name: 'Tracking',
    icon: 'fa-bar-chart',
    id: 'sidenav-tracking'
  },
  SHOP_SETTINGS: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/settings`,
    regex: /^\/settings(?=(\/\d)|($))/,
    name: 'Shop Settings',
    icon: 'fa-gear',
    id: 'sidenav-shop-settings'
  },
  HEALTH: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/health`,
    name: 'Health [A]',
    icon: 'fa-heartbeat',
    id: 'sidenav-health'
  },
  TRBO: {
    path: () => process.env.TRBO_LINKOUT || 'https://charger-v2.trbo.com/login#/admin?partner=chatchamppartner',
    id: 'sidenav-trbo',
    name: 'Onsite Personalization',
    icon: 'trbo'
  },
  KNOWLEDGE_BASE: {
    path: (shop: Shop) => `/shops/${shop.secure_id}/knowledge_base`,
    name: 'Knowledge Base [A]',
    icon: 'fa-book',
    id: 'sidenav-knowledge-base'
  },
  ACCOUNTS: { path: () => '/accounts', name: 'Accounts', icon: 'fa-user-circle-o', id: 'sidenav-accounts' },
  ADMIN: (adminPath) => ({ path: () => adminPath, name: 'Admin', icon: 'fa-user-secret', id: 'sidenav-admin' })
}

export function SideNav ({ adminPath, shops }: Props) {
  const { user } = useUser()
  const [shop, , { setShop }] = useShop()

  return (
    <nav className="flex-column">
      {user.hasRole('admin') && (<SearchModal/>)}
      <section className="sidenav-section">
        <div className="sidenav-logo-item">
          <img className="sidenav-logo" src={logo}/>
        </div>
      </section>
      <section className="sidenav-section">
        <SideNavHeader>Shop</SideNavHeader>
        <div className="height-auto">
          <ShopPicker classes="shop-picker" currentShopName={shop.name} shops={shops} onChangeShop={setShop}/>
        </div>
      </section>
      <section className="sidenav-section">
        <ul className="sidenav-list">
          <SideNavItem shop={shop} item={ITEMS.DASHBOARD}/>
          {(user.hasRole('admin') || shop.hasUserInsights()) && <SideNavItem shop={shop} item={ITEMS.USER_INSIGHTS}/>}
        </ul>
      </section>
      <section className="sidenav-section">
        <SideNavHeader>Chatbots</SideNavHeader>
        <ul className="sidenav-list">
          <SideNavItem shop={shop} item={ITEMS.CHATBOTS}/>
          <SideNavItem shop={shop} item={ITEMS.WELCOME_MESSAGES}/>
          <SideNavItem shop={shop} item={ITEMS.CHATBOT_SETTINGS}/>
        </ul>
      </section>
      <section className="sidenav-section">
        <SideNavHeader>Wizards</SideNavHeader>
        <ul className="sidenav-list">
          <SideNavItem shop={shop} item={ITEMS.WIZARDS}/>
        </ul>
      </section>
      <section className="sidenav-section">
        <SideNavHeader>People</SideNavHeader>
        <ul className="sidenav-list">
          <SideNavItem shop={shop} item={ITEMS.SUBSCRIBERS}/>
          <SideNavItem shop={shop} item={ITEMS.SEGMENTS}/>
        </ul>
      </section>
      <section className="sidenav-section">
        <SideNavHeader>Recommender</SideNavHeader>
        <ul className="sidenav-list">
          <SideNavItem shop={shop} item={ITEMS.SUBSCRIBER_ATTRIBUTES}/>
          <SideNavItem shop={shop} item={ITEMS.PRODUCTS}/>
          <SideNavItem shop={shop} item={ITEMS.FEED_SETTINGS}/>
          <SideNavItem shop={shop} item={ITEMS.RECOMMENDER_SETTINGS}/>
        </ul>
      </section>
      {(user.hasRole('admin') || shop.customer_service_enabled) && (
        <section className="sidenav-section">
          <SideNavHeader>Customer Service AI</SideNavHeader>
          <ul className="sidenav-list">
            <SideNavItem shop={shop} item={ITEMS.CUSTOMER_SERVICE_CONVERSATIONS}/>
            <SideNavItem shop={shop} item={ITEMS.KNOWLEDGE_BASE}/>
          </ul>
        </section>
      )}
      <section className={`sidenav-section ${user.hasRole('user') ? 'no-bottom-border' : ''}`}>
        <SideNavHeader>Settings</SideNavHeader>
        <ul className="sidenav-list">
          <SideNavItem shop={shop} item={ITEMS.INTEGRATION}/>
          <SideNavItem shop={shop} item={ITEMS.TRACKING}/>
          <SideNavItem shop={shop} item={ITEMS.SHOP_SETTINGS}/>
          {user.hasRole('admin') && <SideNavItem shop={shop} item={ITEMS.HEALTH}/>}
        </ul>
      </section>
      {user.hasRole('admin') && (
        <section className="sidenav-section">
          <SideNavHeader>Advanced</SideNavHeader>
          <ul className="sidenav-list">
            <SideNavItem shop={shop} item={ITEMS.ACCOUNTS}/>
          </ul>
          <ul className="sidenav-list">
            <SideNavItem shop={shop} item={ITEMS.ADMIN(adminPath)}/>
          </ul>
        </section>
      )}
      <section className="sidenav-section no-bottom-border">
        <SideNavHeader>Partner</SideNavHeader>
        <ul className="sidenav-list">
          <SideNavItem shop={shop} item={ITEMS.TRBO} openInNewTab/>
        </ul>
      </section>
    </nav>
  )
}
