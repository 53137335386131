// @flow

import React, { useState } from 'react'
import moment from 'moment'

import { ChannelService } from '../../services'
import { Channel } from '../../models'

type Props = {
  isDisplayed: boolean,
  channel: Channel
}

export default function IntegrationAlert (props: Props) {
  const [isDisplayed, setIsDisplayed] = useState(props.isDisplayed)

  async function handleIgnoreOnClick (e: any) {
    e.preventDefault()
    setIsDisplayed(false)
    const updatedChannel = new Channel({
      ...props.channel,
      custom_config: { ...props.channel.custom_config, integrationAlertIgnoredAt: moment().utc().toISOString() }
    })
    await ChannelService.updateChannel(props.channel.id, updatedChannel)
  }

  return (
    isDisplayed &&
    (<div className="sticky-alert alert alert-danger">
      <div className="container">
        <a
          className="pull-right ignore-alert-link"
          href="#"
          onClick={handleIgnoreOnClick}
        >Ignore warning</a>
        <strong>There is some integration problem.</strong>
        <p>
          <a
            href={`/shops/${props.channel.secure_id}/settings/integration`}
            rel="noopener noreferrer"
          >Click here to check your integration settings</a>
        </p>
      </div>
    </div>)
  )
}
