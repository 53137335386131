// @flow

import React from 'react'

type Props = {
  label: string,
  isSelected?: boolean,
  onChange?: Function,
  disabled?: boolean
}

export const CCCheckbox = ({ label, isSelected = false, onChange = () => {}, disabled = false }: Props) => (
  <div className="form-check">
    <label>
      <input
        type="checkbox"
        name={label}
        disabled={disabled}
        checked={isSelected}
        onChange={onChange}
        className="form-check-input"
      />
      {label}
    </label>
  </div>
)
