// @flow

import _ from 'lodash'
import React, { useState } from 'react'

import { CCButton, CCModal, CCTextInput } from '../UI'
import { Shop, Wizard } from '../../models'
import { WizardsService } from '../../services'

type Props = {
  shop: Shop,
  wizard: Wizard,
  onSubmit: Function,
  isOpen: boolean,
  onRequestClose: Function
}

export function CreateModal ({ wizard: w, onRequestClose, onSubmit, isOpen, shop }: Props) {
  const [wizard, setWizard] = useState(w)

  const handleChange = (param: string) => {
    return (value: Object) => {
      // $FlowFixMe
      wizard[param] = value
      // $FlowFixMe
      setWizard(new Wizard(wizard))
    }
  }

  const handleFormSubmit = async () => {
    onRequestClose()

    let persistedWizard
    if (wizard.id) {
      persistedWizard = await WizardsService.update(wizard.id, wizard)
    } else {
      persistedWizard = await WizardsService.create(shop, wizard)
    }

    onSubmit(persistedWizard)
  }

  const { name } = wizard

  return <CCModal title="Create Wizard" width={700} isOpen={isOpen}>
    <div className="cc-modal__field">
      <div className="cc-modal__horizontal-input">
        <div className="cc-modal__field__label">Name:</div>
        <div className="cc-modal__field__body">
          <CCTextInput value={name} onChange={handleChange('name')} error={_.isEmpty(name)}/>
        </div>
      </div>
    </div>
    <div className="cc-modal__footer">
      <CCButton onClick={onRequestClose}>
        Cancel
      </CCButton>
      <CCButton
        color="primary" onClick={handleFormSubmit}
        disabled={_.some([name], _.isEmpty)}>Save</CCButton>
    </div>
  </CCModal>
}
