// @flow

export function isDivisible (dividend: number, divisor: number): boolean {
  return (dividend / divisor) % 1 === 0
}

export function formatNumber (value: number, digits: number = 0, locale: string = 'de-DE'): string {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  }).format(value)
}

export function formatPercentage (value: number, digits: number = 0, locale: string = 'de-DE'): string {
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  }).format(value)
}

export function increaseInPercent (previousValue: ?number, currentValue?: number): number {
  if (!previousValue || !currentValue) return 0
  else return Math.round(((currentValue - previousValue) / previousValue) * 100)
}

export function signedNumber (number: number): string {
  return (number < 0 ? '' : '+') + number
}
