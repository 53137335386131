// @flow

import React from 'react'

import './CCSpinnerSmall.scss'

export function CCSpinnerSmall () {
  return (
    <div className="cc-spinner-small" role="spinner-small">
      <span />
    </div>
  )
}
