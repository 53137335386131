// flow

import React, { useEffect, useRef, useState } from 'react'

import { type Block } from '../../models'

import { getTextWidth } from '../../utils/getTextWidth'
import { CCTruncator } from '../UI'

type Props = {
  block: Block,
  onClick: Function,
  selected: boolean
}

export function BlockButton ({ block, selected, onClick }: Props) {
  const [clamp, setClamp] = useState(0)
  const ref = useRef()

  useEffect(() => {
    function calculateClamp () {
      const fontDescriptor = 'bold 16px Quicksand'
      const domNodeWidth = ref.current.getBoundingClientRect().width
      const textWidth = getTextWidth(block.name, fontDescriptor)
      const characterCount = block.name.length
      const characterWidth = textWidth / characterCount
      const clamp = Math.ceil(domNodeWidth / characterWidth) - 4 /* count for padding */
      setClamp(clamp)
    }

    calculateClamp()
  }, [block])

  return (
    <div
      ref={ref}
      key={`block-${block.id}`}
      onClick={() => onClick(block.id)}
      className={`campaign-blocks__block ${
        selected
          ? 'campaign-blocks__block--active'
          : ''
      }`}
    >
      {clamp && <CCTruncator clamp={clamp}>{block.name}</CCTruncator>}
    </div>
  )
}
