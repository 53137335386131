// @flow

export class KnowledgeItem {
  id: number
  question: string
  answer: string

  constructor (knowledgeItem: Object) {
    knowledgeItem && Object.assign(this, knowledgeItem)
  }
}
