// @flow

import React from 'react'
import { CCTextInput, CCModal, CCButton, CCSpinner, CCCalloutBox } from '../../UI'

import { Attribute } from '../../../models'

import './AttributesValuesModal.scss'
import { AttributeValuesModalEmptyState } from './AttributeValuesModalEmptyState'
import { useAttributeValuesModalHandlers } from './hooks/useAttributeValuesModalHandlers'

type Props = {
  attribute: Attribute,
  onSave: Function,
  isOpen: boolean,
  onRequestClose: Function
}

export function AttributeValuesModal ({
  onSave,
  attribute,
  isOpen,
  onRequestClose
}: Props) {
  const { attributeValues, update, editTranslation, loading } = useAttributeValuesModalHandlers(attribute.id)

  async function handleOnSave () {
    await update()
    onSave()
  }
  return (
    <CCModal title={`Values for "${attribute.name}" attribute`} width={900} isOpen={isOpen}>
      <div className="cc-modal__field">
        {loading
          ? <div className="spinner_block">
              <CCSpinner />
          </div>
          : attributeValues.length === 0
            ? <AttributeValuesModalEmptyState />
            : <div>
                {attribute.isExclude() &&
                  <div className="callout-container">
                    <CCCalloutBox>
                      This is an “exclude” attribute, so you should use the negation of the value for the custom translation. For example: “Without gluten” for “gluten”.
                    </CCCalloutBox>
                  </div>
                }
                <table className="cc-table skinny attributes-table">
                  <thead>
                    <tr>
                      <th>Value</th>
                      <th>Custom translation (Max. of 36 characters)</th>
                      <th className="text-center">Enabled</th>
                    </tr>
                  </thead>
                  <tbody>
                  {attributeValues.map((attributeValue, index) => {
                    return (
                      <tr key={index}>
                        <td>{attributeValue.value}</td>
                        <td>
                          <CCTextInput
                            value={attributeValue.translated_value}
                            onChange={(val) => editTranslation(attributeValue.value, 'translated_value', val)}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            name={attributeValue.value}
                            checked={!(attributeValue.enabled === false)}
                            onChange={(e) => editTranslation(attributeValue.value, 'enabled', e.target.checked)}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
        }
      </div>
      <div className="cc-modal__footer">
        <CCButton onClick={onRequestClose}>
          Cancel
        </CCButton>

        <CCButton color="primary" onClick={handleOnSave}>
          Save
        </CCButton>
      </div>
    </CCModal>
  )
}
