// @flow

import React, { useEffect } from 'react'

import { type IssueStatus } from '../../../../models'
import { useIssues, useShop } from '../../../../hooks'

import { CCButton, CCPageHeader, CCSpinnerPage, showErrorMessage } from '../../../UI'

import { IssuesEmptyList } from './IssuesEmptyList'
import { IssuesTable } from './IssuesTable'
import { IssuesHeader } from './IssuesHeader'

export function IssuesList () {
  const [shop] = useShop()
  const {
    issues,
    isLoadingList,
    pages,
    page,
    count,
    isScanningForErrors,
    filters,
    loadNextPage,
    scan,
    loadIssues,
    applyFilters
  } = useIssues()
  const isShowingEmptyList = issues.length === 0 && !isLoadingList

  async function handleScanClick (): Promise<void> {
    try {
      await scan()
      await loadIssues()
    } catch (error) {
      showErrorMessage('Failed to scan for errors.')
    }
  }

  function handlePageClick (data: { selected: number }): void {
    if ((data.selected + 1) !== page) loadNextPage(data.selected + 1)
  }

  function handleStatusChange (status: IssueStatus): void {
    applyFilters({ ...filters, status })
  }

  function renderContent (): any {
    if (isLoadingList || isScanningForErrors) return <CCSpinnerPage/>
    if (isShowingEmptyList) return <IssuesEmptyList filters={filters} onClick={handleScanClick}/>

    return <IssuesTable issues={issues} pages={pages} page={page} onPageClick={handlePageClick} />
  }

  useEffect(() => {
    if (!shop.secure_id) return

    loadIssues()
  }, [shop])

  return (
    <div>
      <CCPageHeader
        alignActionsToRight
        title="Health"
        description="<span>After scanning, the misconfigurations from your active Chatbots and Wizards are being displayed here.</span>"
      >
        <CCButton
          id="scan-errors"
          icon="bug"
          customClasses="create-btn"
          onClick={handleScanClick}
          disabled={isScanningForErrors}
        >
          Scan for errors
        </CCButton>
      </CCPageHeader>
      <IssuesHeader
        filters={filters}
        issuesCount={count}
        isFilterDisabled={isScanningForErrors || isLoadingList}
        onStatusChange={handleStatusChange}
      />
      {renderContent()}
    </div>
  )
}
