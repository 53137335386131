// @flow

import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { useShop } from '../../hooks'
import { AccountService, ShopStatisticsService } from '../../services'
import { normalizeLocale } from '../../utils/localization'

import { CCPageHeader } from '../UI'

import { DashboardKpiView } from './components/DashboardKpiView'

import './Dashboard.scss'

export function Dashboard () {
  const [shop] = useShop()
  const [accountName, setAccountName] = useState('')
  const [totalKpis, setTotalKpis] = useState({})
  const [thisWeeksKpis, setThisWeeksKpis] = useState({})
  const [lastWeeksKpis, setLastWeeksKpis] = useState({})

  const title = `Welcome, ${accountName} 🙂`
  const locale = normalizeLocale(shop?.locale ?? 'de-DE')

  useEffect(() => {
    async function fetchData () {
      if (!shop?.account_id) return

      const accountName = (await AccountService.getAccount(shop.account_id)).name
      setAccountName(accountName)

      const totalKpis = await ShopStatisticsService.getShopStatistics()
      const thisWeeksKpis = await ShopStatisticsService.getShopStatistics({
        startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'd').format('YYYY-MM-DD')
      })
      const lastWeeksKpis = await ShopStatisticsService.getShopStatistics({
        startDate: moment().subtract(14, 'd').format('YYYY-MM-DD'),
        endDate: moment().subtract(8, 'd').format('YYYY-MM-DD')
      })
      setTotalKpis(totalKpis)
      setThisWeeksKpis(thisWeeksKpis)
      setLastWeeksKpis(lastWeeksKpis)
    }

    fetchData()
  }, [shop])

  return (
    <div>
      <CCPageHeader title={title} customClasses="full-width"/>
      <div className="dashboard-content-wrapper flex-row">
        <DashboardKpiView
          total={totalKpis.consultations_started}
          lastWeekTotal={lastWeeksKpis.consultations_started}
          thisWeekTotal={thisWeeksKpis.consultations_started}
          thisWeekPercentage={thisWeeksKpis.activation_rate}
          lastWeekPercentage={lastWeeksKpis.activation_rate}
          title="Consultations started"
        />
        <DashboardKpiView
          total={totalKpis.consultations_completed}
          lastWeekTotal={lastWeeksKpis.consultations_completed}
          thisWeekTotal={thisWeeksKpis.consultations_completed}
          thisWeekPercentage={thisWeeksKpis.completion_rate}
          lastWeekPercentage={lastWeeksKpis.completion_rate}
          title="Consultations completed"
        />
        <DashboardKpiView
          total={totalKpis.number_of_orders_finished}
          lastWeekTotal={lastWeeksKpis.number_of_orders_finished}
          thisWeekTotal={thisWeeksKpis.number_of_orders_finished}
          thisWeekPercentage={thisWeeksKpis.cvr}
          lastWeekPercentage={lastWeeksKpis.cvr}
          title="Conversions"
        />
        <div className="break-column"/>
        <DashboardKpiView
          isCurrency
          locale={locale}
          currency={shop.currency}
          total={totalKpis.revenue_completed}
          lastWeekTotal={lastWeeksKpis.revenue_completed}
          thisWeekTotal={thisWeeksKpis.revenue_completed}
          title="Revenue"
        />
        <DashboardKpiView
          isCurrency
          locale={locale}
          currency={shop.currency}
          total={totalKpis.average_order_value}
          lastWeekTotal={lastWeeksKpis.average_order_value}
          thisWeekTotal={thisWeeksKpis.average_order_value}
          title="Average Order Value"
        />
        <DashboardKpiView
          isPercentage
          total={totalKpis.recommender_success_rate}
          thisWeekPercentage={thisWeeksKpis.recommender_success_rate}
          lastWeekPercentage={lastWeeksKpis.recommender_success_rate}
          title="Recommender Success Rate"
        />
      </div>
    </div>
  )
}
