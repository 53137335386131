// @flow

import React, { type Node, useState } from 'react'

import { CCModal } from '../CCModal/CCModal'
import { CCButton } from '../CCButton/CCButton'

import './CCConfirmationModal.scss'

type Props = {
  isShowing?: boolean,
  message?: string | Node,
  onConfirm: Function,
  onCancel?: Function,
  children?: Node
}

export function CCConfirmationModal ({
  isShowing = false,
  message = 'Are you sure?',
  onConfirm,
  onCancel,
  children
}: Props) {
  const [isShowingModal, setIsShowingModal] = useState(isShowing)

  function handleClick (): void {
    setIsShowingModal(true)
  }

  function handleConfirm (): void {
    setIsShowingModal(false)
    onConfirm()
  }

  function handleCancel (): void {
    setIsShowingModal(false)
    onCancel?.()
  }

  return (
    <>
      <span onClick={handleClick}>{children}</span>
      {isShowingModal && (
        <CCModal isOpen title="Confirmation">
          <div className="cc-confirmation-modal-content">
            <div className="cc-confirmation-modal-content__message" role="confirmation-message">
              {message}
            </div>
            <div className="cc-confirmation-modal-content__actions">
              <CCButton onClick={handleConfirm}>Yes</CCButton>
              <CCButton color="primary" onClick={handleCancel}>No</CCButton>
            </div>
          </div>
        </CCModal>
      )}
    </>
  )
}
