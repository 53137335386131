// @flow

import React from 'react'

import './CCTooltip.scss'

type Props = {
  title: string,
  iconClass?: string,
  className?: string,
  arrowPosition?: 'top' | 'left'
}

export function CCTooltip (props: Props) {
  const arrowPosition = props.arrowPosition ?? 'top'

  return (
    <div className={['cc-tooltip', props.className].join(' ').trim()}>
      <i className={`cc-tooltip-icon ${props.iconClass || 'fa fa-info-circle'}`} />
      <span className={['cc-tooltip-text', arrowPosition].join(' ').trim()}>{props.title}</span>
    </div>
  )
}
