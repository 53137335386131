// @flow

import { accountUsersModel, type AccountUsersModel } from './accountUsersModel'
import { attributesModel, type AttributesModel } from './attributesModel'
import { attributeValuesModel, type AttributeValuesModel } from './attributeValuesModel'
import { blocksModel } from './blocksModel'
import { categoriesModel } from './categoriesModel'
import { conversationsModel, type ConversationsModel } from './conversationModel'
import { issuesModel } from './issuesModel'
import { knowledgeItemsModel, type KnowledgeItemsModel } from './knowledgeItemsModel'
import { shopModel } from './shopModel'
import { productsModel } from './productsModel'
import { userChatsModel, type UserChatsModel } from './userChatsModel'
import { wizardModel } from './wizardModel'

export type StoreModel = {
  attributes: AttributesModel,
  accountUsers: AccountUsersModel,
  attributeValues: AttributeValuesModel,
  blocks: any,
  categories: any,
  conversations: ConversationsModel,
  issues: any,
  knowledgeItems: KnowledgeItemsModel,
  shop: any,
  products: any,
  userChats: UserChatsModel,
  wizard: any
}

export const model: StoreModel = {
  accountUsers: accountUsersModel,
  attributes: attributesModel,
  attributeValues: attributeValuesModel,
  blocks: blocksModel,
  categories: categoriesModel,
  conversations: conversationsModel,
  issues: issuesModel,
  knowledgeItems: knowledgeItemsModel,
  shop: shopModel,
  products: productsModel,
  userChats: userChatsModel,
  wizard: wizardModel
}
