// @flow

import React, { useEffect, useState } from 'react'

import { ChannelService } from '../../../services'

import { CCCheckbox, CCSpinnerPage } from '../../UI'

import styles from '../../UI/CCCustomizableTable/CCCustomizableTable.css'
import { Column } from './Column'

export default function Pipeline () {
  const [channels, setChannels] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [showLost, setShowLost] = useState(false)
  const [showSignedUp, setShowSignedUp] = useState(false)

  useEffect(() => {
    async function fetchData () {
      setIsLoading(true)
      const channels = await ChannelService.getAdminChannels({
        query: query,
        showLost: showLost,
        showSignedUp: showSignedUp
      })
      setChannels(channels)
      setIsLoading(false)
    }

    fetchData()
  }, [query, showLost, showSignedUp])

  const handleSearch = (event: any) => {
    // $FlowFixMe
    const matchString = event.target.value?.toLowerCase()
    setQuery(matchString)
  }

  const renderPipeline = () => {
    return (<div className="row">
      {showLost && (<div className="col-md-4 pipeline-column">
        <Column channels={channels.filter(c => c.stage === 'lost')} title="Lost"/>
      </div>)}
      {showSignedUp && (<div className="col-md-4 pipeline-column">
        <Column channels={channels.filter(c => c.stage === 'signed_up')} title="Signed Up"/>
      </div>)}
      {!showSignedUp && !showLost && (<>
        <div className="col-md-4 pipeline-column">
          <Column channels={channels.filter(c => c.stage === 'paid')} title="Paying"/>
        </div>
      </>)}
    </div>)
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-default">
            <div className="panel-body">
              <div className={styles.control_container}>
                <input onChange={handleSearch} placeholder="Search..." className="form-control"
                       style={{ width: '300px' }}/>
                <CCCheckbox label="Show Signed Up"
                            onChange={() => setShowSignedUp(!showSignedUp)}
                            isSelected={showSignedUp}/>
                <CCCheckbox label="Show Lost" onChange={() => setShowLost(!showLost)} isSelected={showLost}/>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div id="filterrific_results">
              {(isLoading) ? (<CCSpinnerPage/>) : renderPipeline()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
