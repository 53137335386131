import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { CCDropdown, CCAutoComplete } from '../../UI'
import { AttributesService } from '../../../services'
import QueryBuilderContext from './QueryBuilderContext'

export default class QueryCondition extends Component {
  static propTypes = {
    id: PropTypes.any,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    operator: PropTypes.string,
    tagId: PropTypes.number,
    value: PropTypes.string
  }

  static contextTypes = {
    tagOptions: PropTypes.array
  }

  operatorOptions = [
    {
      title: 'Equal',
      value: 'is',
      showValueField: true
    },
    {
      title: 'Not equal',
      value: 'is_not',
      showValueField: true
    },
    {
      title: 'Has any value',
      value: 'is_any',
      showValueField: false
    },
    {
      title: 'Has no value',
      value: 'is_empty',
      showValueField: false
    },
    {
      title: 'At least once',
      value: 'gte1',
      showValueField: false
    }
  ]

  state = {
    tagId: this.props.tagId,
    operator: this.props.operator,
    value: this.props.value,
    showValueField: true,
    activeOptions: this.operatorOptions
  }

  async componentDidMount () {
    let subscriberAttribute = {}
    if (this.state.tagId) {
      subscriberAttribute = await AttributesService.getAttribute(this.state.tagId)
    }
    const showValueField = this.operatorOptions.find((option) => option.value === this.props.operator).showValueField
    this.setState({ showValueField: showValueField, activeOptions: this.filteredOperatorOptions(subscriberAttribute.name) })
  }

  handleTagChange = (tagOption) => {
    const activeOptions = this.filteredOperatorOptions(tagOption.label)
    let { operator, showValueField } = this.state
    if (!activeOptions.find((o) => o.value === this.state.operator)) {
      operator = activeOptions[0].value
      showValueField = activeOptions[0].showValueField
    }
    this.setState(
      {
        tagId: tagOption.value,
        activeOptions: activeOptions,
        operator: operator,
        showValueField: showValueField
      },
      () => {
        this.props.onChange(
          this.props.id,
          _.pick(this.state, ['value', 'operator', 'tagId'])
        )
      }
    )
  }

  handleOperatorChange = (operatorOption) => {
    this.setState(
      {
        operator: operatorOption.value,
        showValueField: operatorOption.showValueField
      },
      () => {
        this.props.onChange(
          this.props.id,
          _.pick(this.state, ['operator', 'tagId'])
        )
      }
    )
  }

  handleValueChanged = (value) => {
    this.setState(
      {
        value
      },
      () => {
        this.props.onChange(
          this.props.id,
          _.pick(this.state, ['value', 'operator', 'tagId'])
        )
      }
    )
  }

  handleDelete = () => {
    this.props.onDelete(this.props.id)
  }

  shouldRenderValueField = () => {
    return this.state.showValueField
  }

  filteredOperatorOptions = (attributeName) => {
    const operatorOptions = JSON.parse(JSON.stringify(this.operatorOptions))

    if (attributeName === 'Purchased Product') {
      const filteredOptions = Object.values(_.pickBy(operatorOptions, (v) => (['gte1', 'is_empty'].includes(v.value))))
      const neverIndex = filteredOptions.findIndex((op) => op.value === 'is_empty')
      filteredOptions[neverIndex].title = 'Never'
      return filteredOptions
    }

    return this.operatorOptions
  }

  render () {
    const { tagId, operator, value } = this.props
    return (
      <QueryBuilderContext.Consumer>
        {({ tagOptions }) => (
          <div className="cc-query-condition">
            <div className="cc-query-condition__dropdown">
              <CCDropdown
                autoCompleteEnabled
                value={tagId}
                onChange={this.handleTagChange}
                options={tagOptions}
                placeholder='Select Attribute'
              />
            </div>
            <div className="cc-query-condition__dropdown operator">
              <CCDropdown
                value={operator}
                onChange={this.handleOperatorChange}
                options={this.state.activeOptions}
              />
            </div>
            {this.shouldRenderValueField() &&
            <div className="cc-query-condition__text-input">
              <CCAutoComplete
                getDataFn={(query) => AttributesService.autoCompleteAttributeValues(tagId, query)}
                selectDataArrayFn={(res) => res}
                mapItemToOptionFn={(item) => ({ title: item, value: item })} value={value}
                placeholder="Enter Text"
                onChange={this.handleValueChanged}/>
            </div>}
            <div className="cc-query-condition__actions">
              <i onClick={this.handleDelete} className="fa fa-trash"/>
            </div>
          </div>
        )}
      </QueryBuilderContext.Consumer>
    )
  }
}
