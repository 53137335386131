// @flow

import React from 'react'
import { CCTooltip } from '../UI'
import './ImportAttributesList.scss'

const PRODUCT_IMPORT_ATTRIBUTES_LIST = {
  title: 'Title',
  link: 'URL',
  image_link: 'Image URL',
  category: 'Category',
  price: 'Price',
  sale_price: 'Sale price',
  brand: 'Brand',
  stock: 'Stock',
  description: 'Description'
}

type Props = {
  disabledAttributes: Array<String>,
  onChange: Function
}

export function ImportAttributesList (props: Props) {
  const handleChange = (attribute) => {
    const newDisabledAttributes = [...props.disabledAttributes]

    if (newDisabledAttributes.includes(attribute)) {
      newDisabledAttributes.splice(newDisabledAttributes.indexOf(attribute), 1)
    } else {
      newDisabledAttributes.push(attribute)
    }

    props.onChange(newDisabledAttributes)
  }

  return (
    <div className='cc-modal__horizontal-input'>
      <div className="cc-modal__field__label">
        Attributes <CCTooltip title={'The selected attributes will only be imported if they are included in the product feed.'}/>
      </div>
      <div className="cc-modal__field__body">
        <ul className="import-attributes-list">
          {Object.keys(PRODUCT_IMPORT_ATTRIBUTES_LIST).map((attribute, index) => {
            return (
              <li key={index}>
                <div className="import-attributes-list-item">
                  <input
                    type="checkbox"
                    id={index}
                    name={attribute}
                    value={attribute}
                    checked={!props.disabledAttributes.includes(attribute)}
                    onChange={() => handleChange(attribute)}
                    role="item-checkbox"
                  />
                  <label htmlFor={index}>{PRODUCT_IMPORT_ATTRIBUTES_LIST[attribute]}</label>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
