// @flow

import { CCRoutes } from '../utils/routes'
import { KnowledgeItem, Shop } from '../models'

import { axiosJs, axiosJson } from './utils'

export type KnowledgeItemsUploadStatus =
  | 'in_progress'
  | 'completed'

export class KnowledgeItemsService {
  static import (shop: Shop, formData: any): Promise<any> {
    return axiosJs.post(CCRoutes.importKnowledgeItemsPath(shop), formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  static getList (shop: Shop, params: Object = {}): Promise<{ knowledgeItems: Array<KnowledgeItem>, pages: number, page: number, count: number }> {
    return axiosJson.get(CCRoutes.knowledgeItemsPath(shop, params))
      .then(({ data }) => ({
        knowledgeItems: data.knowledge_items.map(issue => new KnowledgeItem(issue)),
        pages: data.pages,
        page: data.page,
        count: data.count
      }))
  }

  static loadUploadStatus (shop: Shop, params: Object = {}): Promise<KnowledgeItemsUploadStatus> {
    return axiosJson.get(CCRoutes.knowledgeItemsUploadStatusPath(shop, params))
      .then(({ data }) => data.status)
  }
}
