// @flow

import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
// $FlowFixMe
import 'react-tabs/style/react-tabs.scss'

import { CCDateRangePicker, CCPageHeader, CCSpinnerPage } from '../../UI'
import { DateFilter } from '../../../utils/dateFilter'

import { Wizard } from '../../../models'
import { WizardsService } from '../../../services'

import { WizardDropRatesTable } from './WizardDropRatesTable'

type Props = {
  wizard: Wizard
}

const today = new Date()

export default function WizardReport ({ wizard }: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const dateFilter: DateFilter = new DateFilter('wizards:analytics-date-range')
  const [startDate, setStartDate] = useState(dateFilter.loadDateRangeFromSession().startDate)
  const [endDate, setEndDate] = useState(dateFilter.loadDateRangeFromSession().endDate)
  const [pages, setPages] = useState([])

  useEffect(() => {
    async function getWizardPages () {
      setIsLoading(true)
      const wizardPages = await WizardsService.dropRates(wizard, startDate, endDate)
      setPages(wizardPages)
      setIsLoading(false)
    }

    getWizardPages()
  }, [startDate, endDate])

  function handleDateRangeChanged ({ startDate, endDate }: Object) {
    dateFilter.saveDateRangeInSession({ startDate, endDate })
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return (
    <div className="campaign-report">
      <CCPageHeader
        title={wizard.name}
        description={'Wizard analytics report'}
        right={
          <div className="flex">
            <span className="header-label">Show analytics for dates</span>
            <div className="flex-column">
              <CCDateRangePicker
                startDate={startDate}
                endDate={endDate}
                maxDate={today}
                onChange={handleDateRangeChanged}
              />
            </div>
          </div>}
      />
      <div className={'container margin-top-30'}>
        {(isLoading)
          ? (<CCSpinnerPage/>)
          : (<Tabs>
            <TabList>
              <Tab>Drop Rates</Tab>
            </TabList>
            <TabPanel>
              <WizardDropRatesTable
                pages={pages}/>
            </TabPanel>
          </Tabs>)}
      </div>
    </div>
  )
}
