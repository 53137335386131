// @flow

import { Action, action, Thunk, thunk } from 'easy-peasy'

import { KnowledgeItemsService } from '../services'
import { KnowledgeItem, Shop } from '../models'

type FetchPayload = {
  shop: Shop,
  params: {
    page: number
  }
}

type ImportFilePayload = {
  shop: Shop,
  file: File
}

type LoadUploadStatusPayload = {
  shop: Shop
}

export type KnowledgeItemsModel = {
  knowledgeItems: Array<KnowledgeItem>,
  pages: number,
  page: number,
  count: number,
  isLoadingList: boolean,
  isUploading: boolean,
  setKnowledgeItems: Action<KnowledgeItemsModel, Array<KnowledgeItem>>,
  setIsLoadingList: Action<KnowledgeItemsModel, boolean>,
  setIsUploading: Action<KnowledgeItemsModel, boolean>,
  setPages: Action<KnowledgeItemsModel, number>,
  setPage: Action<KnowledgeItemsModel, number>,
  setCount: Action<KnowledgeItemsModel, number>,
  fetch: Thunk<KnowledgeItemsModel, FetchPayload>,
  importFile: Thunk<KnowledgeItemsModel, ImportFilePayload>,
  loadUploadStatus: Thunk<KnowledgeItemsModel, LoadUploadStatusPayload>
}

export const knowledgeItemsModel: KnowledgeItemsModel = {
  knowledgeItems: [],
  pages: 0,
  page: 0,
  count: 0,
  isLoadingList: true,
  isUploading: false,
  setKnowledgeItems: action((state, payload) => {
    state.knowledgeItems = payload
  }),
  setIsLoadingList: action((state, payload) => {
    state.isLoadingList = payload
  }),
  setPages: action((state, payload) => {
    state.pages = payload
  }),
  setPage: action((state, payload) => {
    state.page = payload
  }),
  setCount: action((state, payload) => {
    state.count = payload
  }),
  setIsUploading: action((state, payload) => {
    state.isUploading = payload
  }),
  fetch: thunk(async (actions, payload) => {
    actions.setIsLoadingList(true)
    const { knowledgeItems, pages, page, count } = await KnowledgeItemsService.getList(payload.shop, payload.params)
    actions.setKnowledgeItems(knowledgeItems)
    actions.setPages(pages)
    actions.setPage(page)
    actions.setCount(count)
    actions.setIsLoadingList(false)
  }),
  importFile: thunk(async (actions, payload) => {
    const formData = new FormData()
    formData.append('file', payload.file)
    actions.setIsUploading(true)
    await KnowledgeItemsService.import(payload.shop, formData)
  }),
  loadUploadStatus: thunk(async (actions, payload) => {
    const uploadStatus = await KnowledgeItemsService.loadUploadStatus(payload.shop)
    actions.setIsUploading(uploadStatus === 'in_progress')
  })
}
