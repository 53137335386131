// @flow

import _ from 'lodash'
import React from 'react'

import { CCDropdown, CCTextInput } from '../../UI'

import { useAccount } from '../../../hooks'
import { ACCOUNT_STAGES } from '../../../models'

const stageDropdownOptions: Array<{ value: string, label: string }> = Object.keys(ACCOUNT_STAGES).map((value) => ({
  value,
  label: ACCOUNT_STAGES[value]
}))

export function AccountAdminPanel () {
  const { account, updateAccount } = useAccount()

  function handleChange (field: string): Function {
    return async (value: string): Promise<void> => {
      await updateAccount({ [field]: value })
    }
  }

  return (<div className="settings-wrapper">
    <div className="settings-item-wrapper">
      <h2 className="settings-header">Account</h2>
      <br/>
      <h3 className="settings-subheader">
        Name
      </h3>
      <CCTextInput
        autoFocus
        value={account.name}
        errorMessage="Name is required"
        error={!account.name}
        onChange={_.debounce(handleChange('name'), 1000)}/>
      <br/>
      <h3 className="settings-subheader">
        Stage
      </h3>
      <CCDropdown
        value={account.stage}
        onChange={option => handleChange('stage')(option.value)}
        options={stageDropdownOptions}
      />
    </div>
  </div>)
}
