// @flow

import React from 'react'
import './CCRadioInput.scss'

type Props = {
  name: string,
  value: any,
  checked: boolean,
  label: string,
  onChange: Function
}

export function CCRadioInput (props: Props) {
  return <label className="cc-radio-input">
    <input type="radio" name={props.name} value={props.value} checked={props.checked} onChange={props.onChange} />
    {props.label}
  </label>
}
