// @flow

import toastr from 'toastr'

export function showErrorMessages (error: Object) {
  let errorMessages
  if (Array.isArray(error)) {
    errorMessages = error
  } else if (error.response && error.response.data?.error_messages) {
    errorMessages = error.response.data.error_messages
  }

  if (errorMessages) {
    toastr.remove()
    for (const errorMsg of errorMessages) {
      toastr.error(errorMsg)
    }
  } else if (error.message) {
    toastr.remove()
    toastr.error(error.message)
  }
}

export function showSuccessMessage (message: string) {
  toastr.success(message)
}

export function showErrorMessage (message: string) {
  toastr.error(message)
}

export async function showFeedbackMessage (asyncFunc: Function, successMessage: string, failureCallback: ?Function) {
  try {
    const result = await asyncFunc()
    toastr.remove()
    toastr.success(successMessage)
    return result
  } catch (error) {
    showErrorMessages(error)
    if (failureCallback) failureCallback(error)
  }
}
