// @flow

import React from 'react'

import { CHATBOT_TRACKING_EVENTS, WIZARD_TRACKING_EVENTS } from '@chatchamp/chatchamp-types'

import { Shop } from '../../../../../models'

import { GenericEventsPanel, type DefaultPayloads } from './GenericEventsPanel'

type Props = {
  name?: string,
  customChatbotPanelTitle?: string,
  customWizardPanelTitle?: string,
  trackerPropertyPath: string,
  shop: Shop,
  onChange: Function,
  eventConfigPanel?: React$StatelessFunctionalComponent<any>,
  isEventNameCustomizable?: boolean,
  chatbotDefaultPayloads?: DefaultPayloads,
  wizardDefaultPayloads?: DefaultPayloads
}

export function GenericPanel ({
  shop,
  name,
  customChatbotPanelTitle,
  customWizardPanelTitle,
  trackerPropertyPath,
  onChange,
  eventConfigPanel,
  isEventNameCustomizable,
  chatbotDefaultPayloads,
  wizardDefaultPayloads
}: Props) {
  const chatbotPanelTitle = customChatbotPanelTitle || `${name || ''} Chatbot`
  const wizardPanelTitle = customWizardPanelTitle || `${name || ''} Wizard`

  return (<>
    <GenericEventsPanel
      shop={shop}
      onChange={onChange}
      trackerPropertyPath={trackerPropertyPath}
      eventsProp="events"
      eventOptions={CHATBOT_TRACKING_EVENTS}
      panelTitle={chatbotPanelTitle}
      eventConfigPanel={eventConfigPanel}
      isEventNameCustomizable={isEventNameCustomizable}
      defaultPayloads={chatbotDefaultPayloads}/>
    <br/>
    <GenericEventsPanel
      shop={shop}
      onChange={onChange}
      trackerPropertyPath={trackerPropertyPath}
      eventsProp="wizardEvents"
      eventOptions={WIZARD_TRACKING_EVENTS}
      panelTitle={wizardPanelTitle}
      eventConfigPanel={eventConfigPanel}
      isEventNameCustomizable={isEventNameCustomizable}
      defaultPayloads={wizardDefaultPayloads}/>
  </>)
}
