// @flow

import _ from 'lodash'
import { useEffect, useMemo } from 'react'

import { Shop } from '../models'
import { useStoreActions, useStoreState } from '../store'
import { usePath } from './usePath'

export function useShop (validateShop: Function = () => true) {
  const { shop, status } = useStoreState((store) => store.shop)
  const { fetchShop, setShop } = useStoreActions((store) => store.shop)
  const path = usePath()

  const secureId = useMemo(() => {
    const hasSecureId = path?.includes('/shops/')

    if (hasSecureId) {
      return path.split('/')[2]
    }
  }, [path])

  const saveShop = _.debounce(useStoreActions(actions => actions.shop.saveShop), 1000)

  // Dominik; 25.04.2023; this should only be called once per request, however, due to our structure of mixing
  // SSR and SPA with the react-rails bridge this can only be fixed once we have one global store
  useEffect(() => {
    if (status === 'idle') {
      if (secureId) fetchShop({ secureId })
      else fetchShop()
    }
  }, [fetchShop, secureId, status])

  const handlePropertyChange = (propertyPath: string, value: any): Shop => {
    const updatedShop = new Shop({ ...shop })
    _.set(updatedShop, propertyPath, value)

    if (validateShop(updatedShop)) saveShop(updatedShop)

    return updatedShop
  }

  return [shop, handlePropertyChange, { setShop, status }]
}
