// @flow

import React from 'react'
import classnames from 'classnames'

import ReactModal from 'react-modal'

import './CCModal.scss'

type Props = {
  children: any,
  title?: any,
  width?: number,
  customClasses?: string,
  headerButton?: any,
  isOpen: boolean,
  onRequestClose?: Function
}

export function CCModal ({ children, title, width = 450, customClasses, headerButton, isOpen, onRequestClose }: Props) {
  const classes = classnames('cc-modal__body', customClasses)

  return (<ReactModal
    overlayClassName="cc-modal-overlay"
    contentLabel={title}
    isOpen={isOpen}
    className={`cc-modal cc-modal-${width}`}
    shouldCloseOnOverlayClick={false}
    appElement={document.querySelector('.content-wrapper')}
    onRequestClose={onRequestClose}
  >
    {title && <div className="cc-modal__header">
      <span>{title}</span>
      {headerButton}
    </div>}
    <div className={classes}>
      {children}
    </div>
  </ReactModal>)
}
