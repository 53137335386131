// @flow

import React from 'react'
import _ from 'lodash'

import { Message } from '../../../../models'

import {
  CCDropdown,
  CCFormField,
  CCJsonEditor,
  CCTextInput,
  type DropdownOption,
  showErrorMessage
} from '../../../UI'

type Props = {
  message: Message,
  handlePropertyChange: Function
}

const HTTP_METHOD_OPTIONS = [{
  label: 'POST',
  value: 'post'
}, {
  label: 'GET',
  value: 'get'
}, {
  label: 'PUT',
  value: 'put'
}, {
  label: 'OPTIONS',
  value: 'options'
}]

function hasToBeHttps (url: string): boolean {
  if (!url) return true
  if (url.startsWith('https://')) return true

  showErrorMessage('URL must start with https://')
  return false
}

export function BasicTab ({ message, handlePropertyChange }: Props) {
  return (
    <>
      <CCFormField label="Webhook URL">
        <CCTextInput
          role="webhook-url-input"
          customClasses="webhook-url-input"
          onBlur={handlePropertyChange('webhook_url', false, hasToBeHttps)}
          onPressEnterKey={handlePropertyChange('webhook_url', false, hasToBeHttps)}
          value={message.webhook_url}/>
      </CCFormField>

      <CCFormField
        label="Headers (optional)"
        tooltip="In the input below you can define custom headers that will get appended to the default ones of the webhook request. Custom headers will override the default ones if they have the same key."
        customClasses="cc-webhook__editor"
      >
        <CCJsonEditor
          height="100px"
          value={message.data?.webhook?.headers || ''}
          onChange={_.debounce(handlePropertyChange('data.webhook.headers', false), 1000)}/>
      </CCFormField>

      <CCFormField label="Body" customClasses="cc-webhook__editor">
        <CCJsonEditor
          height="200px"
          value={message.webhook_data || ''}
          onChange={_.debounce(handlePropertyChange('webhook_data', false), 1000)}/>
      </CCFormField>

      <CCFormField>
        <input
          style={{ display: 'inline-block', marginRight: '5px' }}
          type="checkbox" checked={!(message.data.webhookSerializeAttributes === false)}
          className="checkbox"
          onChange={(e: any) => handlePropertyChange('data.webhookSerializeAttributes', false)(e.target.checked)}/>
        Automatically merge and send subscriber attributes
      </CCFormField>
      <CCFormField label="HTTP method:">
        <CCDropdown
          value={message.data.webhook?.httpMethod || 'post'}
          onChange={(option: DropdownOption) => handlePropertyChange('data.webhook.httpMethod', false)(option.value)}
          options={HTTP_METHOD_OPTIONS}
        />
      </CCFormField>
    </>
  )
}
