// @flow

import React, { Component } from 'react'
import moment from 'moment'
import { DateRangePicker } from 'react-date-range'

import { CCButton } from '../CCButton/CCButton'

import './CCDateRangePicker.scss'

type Props = {
  startDate: Date,
  endDate: Date,
  onChange: Function
}

type State = {
  startDate: Date,
  endDate: Date,
  currentStartDate: Date,
  currentEndDate: Date,
  isOpen: boolean
}

export class CCDateRangePicker extends Component<Props, State> {
  static defaultProps = {
    startDate: moment().subtract(120, 'days').toDate(),
    endDate: moment().subtract(1, 'days').toDate()
  }

  state = {
    startDate: this.props.startDate,
    endDate: this.props.endDate,
    currentStartDate: this.props.startDate,
    currentEndDate: this.props.endDate,
    isOpen: false
  }

  handleChange = ({ selection }: any) => {
    const { startDate, endDate } = selection

    this.setState({
      currentStartDate: startDate,
      currentEndDate: endDate
    })
  }

  handleInputClicked = () => {
    this.setState({ isOpen: true })
  }

  handleApplyClicked = () => {
    this.setState({
      isOpen: false,
      startDate: this.state.currentStartDate,
      endDate: this.state.currentEndDate
    }, () => {
      const { startDate, endDate } = this.state
      this.props.onChange({
        startDate,
        endDate
      })
    })
  }

  handleCancelClicked = () => {
    this.setState({ isOpen: false })
  }

  render () {
    const { isOpen, startDate, endDate, currentStartDate, currentEndDate } = this.state

    return <div className="cc-date-range-picker">
      {isOpen && <div className="cc-date-range-picker__backdrop"/>}
      <div className="cc-date-range-picker__inputs" onClick={this.handleInputClicked}>
        <div className="cc-date-range-picker__start-input">
          {moment(startDate).format('MM/DD/YYYY')}
        </div>
        →
        <div className="cc-date-range-picker__end-input">
          {moment(endDate).format('MM/DD/YYYY')}
        </div>
      </div>
      {isOpen &&
      <div className="cc-date-range-picker__picker">
        <DateRangePicker {...this.props} rangeColors={['#068799']} ranges={[{
          startDate: currentStartDate,
          endDate: currentEndDate,
          key: 'selection'
        }]}
        onChange={this.handleChange}/>
        <div className="cc-date-range-picker__actions">
          <CCButton customClasses="cancel-button"
            onClick={this.handleCancelClicked}>Cancel</CCButton>

          <CCButton color="primary" onClick={this.handleApplyClicked}>Apply</CCButton>
        </div>
      </div>}
    </div>
  }
}
