// @flow

/* eslint-disable camelcase */

export class AttributeValue {
  value: string
  translated_value: string
  enabled: boolean
}

/* eslint-enable camelcase */
